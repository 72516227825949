@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  font-display: fallback;
  src: url("./Pretendard-Regular.subset.woff2") format("woff2");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 500;
  font-display: fallback;
  src: url("./Pretendard-Medium.subset.woff2") format("woff2");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 600;
  font-display: fallback;
  src: url("./Pretendard-SemiBold.subset.woff2") format("woff2");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 700;
  font-display: fallback;
  src: url("./Pretendard-Bold.subset.woff2") format("woff2");
}

/* @font-face {
  font-family: "Pretendard";
  src: url("./PretendardStd-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
} */
